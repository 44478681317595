import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest, normalRequest } from "../middleware/utils";

const initialState = {
  newBlog: null,
  createSuccess: false,
  createError: null,
  isCreating: false,
  blogs: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const createBlog = (newForm) => async (dispatch) => {
  dispatch({ type: "blog/isCreating" });

  try {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const response = await authenticatedRequest.post(
      `/blogs/create-blog`,
      newForm,
      config,
      { withCredentials: true }
    );

    dispatch({
      type: "blog/createSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blog/createError",
      payload: error.response.data.message,
    });
  }
};

export const fetchBlog = () => async (dispatch) => {
  dispatch({ type: "blog/isFetching" });

  try {
    const response = await normalRequest.get(`/blogs/get-blogs`);

    dispatch({
      type: "blog/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blog/fetchError",
      payload: error.response.data.message,
    });
  }
};

export const deleteBlog = (id) => async (dispatch) => {
  dispatch({ type: "blog/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/blogs/delete-blog/${id}`
    );

    dispatch({
      type: "blog/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blog/deleteError",
      payload: error.response.data.message,
    });
  }
};

const blogReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("blog/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = false;
      state.createError = null;
      state.newBlog = null;
    })
    .addCase("blog/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = true;
      state.createError = null;
      state.newBlog = action.payload;
    })
    .addCase("blog/createError", (state, action) => {
      state.isCreating = false;
      state.createSuccess = false;
      state.createError = action.payload;
      state.newBlog = null;
    })
    .addCase("blog/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.blogs = null;
    })
    .addCase("blog/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.blogs = action.payload;
    })
    .addCase("blog/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.blogs = null;
    })
    .addCase("blog/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("blog/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("blog/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default blogReducer;
