import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Stat from "../../components/stat/Stat";
import { fetchOrders } from "../../redux/order";
import { fetchProduct } from "../../redux/product";
import { totalSells } from "../../redux/stat";
import "./Dashboard.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.stat?.totalSells);
  const fetchSuccess = useSelector((state) => state?.order?.fetchSuccess);
  const products = useSelector((state) => state?.product);

  useEffect(() => {
    dispatch(totalSells());
  }, []);

  useEffect(() => {
    dispatch(fetchOrders());
  }, []);

  useEffect(() => {
    dispatch(fetchProduct());
  }, []);

  return (
    <div className="dashboard">
      <div className="wrapper">
        <div className="heading">
          <span>Dashboard</span>
        </div>
        <div className="statContainer">
          <Stat
            title="Total Products"
            isLoading={products?.products?.success ? false : true}
            amount={
              products?.products?.success && products?.products?.products.length
            }
            desc="Number of Products"
          />
          <Stat
            title="Total Orders"
            isLoading={fetchSuccess?.success ? false : true}
            amount={fetchSuccess?.success && fetchSuccess?.orders.length}
            desc="Total Number of orders Recieved"
          />
          <Stat
            title="Total Sells in ETB"
            isLoading={data?.totals[0]?.total ? false : true}
            amount={data?.totals[0]?.total ? data?.totals[0]?.total : 0}
            desc="total Amount Made from Product Sell"
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
