import axios from "axios";
import { server } from "../server";

// Retrieve user token from localStorage
const admin = localStorage.getItem("admin")
  ? JSON.parse(localStorage.getItem("admin"))
  : null;
const token = admin ? admin.token : null;

// Create axios instances with or without authentication headers
export const normalRequest = axios.create({
  baseURL: server,
});
export const authenticatedRequest = axios.create({
  baseURL: server,
  headers: { authorization: token ? `Bearer ${token}` : null },
});

// Define your formatter as before
export const formatter = new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "long",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
