import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest, normalRequest } from "../middleware/utils";

const initialState = {
  newProduct: null,
  createSuccess: false,
  createError: null,
  isCreating: false,
  products: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const createProduct = (newForm) => async (dispatch) => {
  dispatch({ type: "product/isCreating" });

  try {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const response = await authenticatedRequest.post(
      `/products/create-product`,
      newForm,
      config,
      { withCredentials: true }
    );

    dispatch({
      type: "product/createSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "product/createError",
      payload: error.response.data.message,
    });
  }
};

export const fetchProduct = () => async (dispatch) => {
  dispatch({ type: "product/isFetching" });

  try {
    const response = await normalRequest.get(`/products/get-products`);

    dispatch({
      type: "product/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "product/fetchError",
      payload: error.response.data.message,
    });
  }
};

export const deleteProduct = (id) => async (dispatch) => {
  dispatch({ type: "product/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/products/delete-product/${id}`
    );

    dispatch({
      type: "product/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "product/deleteError",
      payload: error.response.data.message,
    });
  }
};

const productReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("product/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = false;
      state.createError = null;
      state.newProduct = null;
    })
    .addCase("product/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = true;
      state.createError = null;
      state.newProduct = action.payload;
    })
    .addCase("product/createError", (state, action) => {
      state.isCreating = false;
      state.createSuccess = false;
      state.createError = action.payload;
      state.newProduct = null;
    })
    .addCase("product/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.products = null;
    })
    .addCase("product/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.products = action.payload;
    })
    .addCase("product/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.products = null;
    })
    .addCase("product/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("product/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("product/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default productReducer;
