import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest, normalRequest } from "../middleware/utils";

const initialState = {
  isCreating: false,
  createSuccess: null,
  createError: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const createGallery = (newForm) => async (dispatch) => {
  dispatch({ type: "gallery/isCreating" });
  try {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const response = await authenticatedRequest.post(
      `/galleries/create-gallery`,
      newForm,
      config,
      { withCredentials: true }
    );
    dispatch({
      type: "gallery/createSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "gallery/createError",
      payload: error.response.data.message,
    });
  }
};

export const deleteGallery = (id) => async (dispatch) => {
  dispatch({ type: "gallery/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/galleries/delete-gallery/${id}`
    );

    dispatch({
      type: "gallery/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "gallery/deleteError",
      payload: error.response.data.message,
    });
  }
};

export const fetchGalleries = () => async (dispatch) => {
  dispatch({ type: "gallery/isFetching" });

  try {
    const response = await normalRequest.get(`/galleries/fetch-gallery`);

    dispatch({
      type: "gallery/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "gallery/fetchError",
      payload: error.response.data.message,
    });
  }
};

const GalleryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("gallery/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = null;
      state.createError = null;
    })
    .addCase("gallery/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = action.payload;
      state.createError = null;
    })
    .addCase("gallery/createError", (state, action) => {
      state.isCreating = false;
      state.createError = action.payload;
      state.createSuccess = null;
    })
    .addCase("gallery/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
    })
    .addCase("gallery/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = action.payload;
      state.fetchError = null;
    })
    .addCase("gallery/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = null;
      state.fetchError = action.payload;
    })
    .addCase("gallery/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("gallery/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("gallery/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default GalleryReducer;
