import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest, normalRequest } from "../middleware/utils";

const initialState = {
  newProductCategory: null,
  createSuccess: false,
  createError: null,
  isCreating: false,
  productCategories: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const createProductCategory =
  (title, description) => async (dispatch) => {
    dispatch({ type: "productCategory/isCreating" });

    try {
      const response = await authenticatedRequest.post(
        `/product-categories/create-product-category`,
        {
          title,
          description,
        },
        { withCredentials: true }
      );

      dispatch({
        type: "productCategory/createSuccess",
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: "productCategory/createError",
        payload: error.response.data.message,
      });
    }
  };

export const fetchProductCategory = () => async (dispatch) => {
  dispatch({ type: "productCategory/isFetching" });

  try {
    const response = await normalRequest.get(
      `/product-categories/get-product-categories`
    );

    dispatch({
      type: "productCategory/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "productCategory/fetchError",
      payload: error.response.data.message,
    });
  }
};

export const deleteProductCategory = (id) => async (dispatch) => {
  dispatch({ type: "productCategory/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/product-categories/delete-product-category/${id}`
    );

    dispatch({
      type: "productCategory/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "productCategory/deleteError",
      payload: error.response.data.message,
    });
  }
};

const productCategoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("productCategory/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = false;
      state.createError = null;
      state.newProductCategory = null;
    })
    .addCase("productCategory/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = true;
      state.createError = null;
      state.newProductCategory = action.payload;
    })
    .addCase("productCategory/createError", (state, action) => {
      state.isCreating = false;
      state.createSuccess = false;
      state.createError = action.payload;
      state.newProductCategory = null;
    })
    .addCase("productCategory/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.productCategories = null;
    })
    .addCase("productCategory/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.productCategories = action.payload;
    })
    .addCase("productCategory/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.productCategories = null;
    })
    .addCase("productCategory/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("productCategory/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("productCategory/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default productCategoryReducer;
