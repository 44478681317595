import { MdOutlineCancel } from "react-icons/md";
import Button from "../navbar/Button";
import { useStateContext } from "../../context/ContextProvider";
import avatar from "../../data/14.png";
import { useSelector, useDispatch } from "react-redux";
import { CgProfile } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../../redux/login";

const UserProfile = () => {
  const { handleClose } = useStateContext();
  const admin = localStorage.getItem("admin")
    ? JSON.parse(localStorage.getItem("admin"))
    : null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("admin");
    navigate(0);
  };

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">
          Admin Profile
        </p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(51, 52, 53)"
          bgHoverColor="light-gray"
          size="xl"
          borderRadius="50%"
          customFun={() => handleClose("notification")}
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatar}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {" "}
            {admin?.user.name}{" "}
          </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">
            {" "}
            Administrator{" "}
          </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {" "}
            {admin?.user.email}{" "}
          </p>
        </div>
      </div>
      <div>
        <Link to="/profile">
          <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
            <button
              type="button"
              style={{ color: "#32412b", backgroundColor: "#d2e7c8" }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
              <CgProfile />
            </button>

            <div>
              <p className="font-semibold dark:text-gray-200 ">Profile</p>
              <p className="text-gray-500 text-sm dark:text-gray-400">
                {" "}
                Admin Account{" "}
              </p>
            </div>
          </div>
        </Link>
      </div>
      <div onClick={handleLogout} className="mt-5 w-[100%] flex justify-end">
        <Button
          color="white"
          bgColor="#32412b"
          text="Logout"
          borderRadius="10px"
          width="full"
        />
      </div>
    </div>
  );
};

export default UserProfile;
