import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest, normalRequest } from "../middleware/utils";

const initialState = {
  newBlogCategory: null,
  createSuccess: false,
  createError: null,
  isCreating: false,
  blogCategories: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const createBlogCategory = (title, description) => async (dispatch) => {
  dispatch({ type: "blogCategory/isCreating" });

  try {
    const response = await authenticatedRequest.post(
      `/blog-categories/create-blog-category`,
      {
        title,
        description,
      },
      { withCredentials: true }
    );

    dispatch({
      type: "blogCategory/createSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blogCategory/createError",
      payload: error.response.data.message,
    });
  }
};

export const fetchBlogCategory = () => async (dispatch) => {
  dispatch({ type: "blogCategory/isFetching" });

  try {
    const response = await normalRequest.get(
      `/blog-categories/get-blog-categories`
    );

    dispatch({
      type: "blogCategory/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blogCategory/fetchError",
      payload: error.response.data.message,
    });
  }
};

export const deleteBlogCategory = (id) => async (dispatch) => {
  dispatch({ type: "blogCategory/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/blog-categories/delete-blog-category/${id}`
    );

    dispatch({
      type: "blogCategory/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "blogCategory/deleteError",
      payload: error.response.data.message,
    });
  }
};

const blogCategoryReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("blogCategory/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = false;
      state.createError = null;
      state.newBlogCategory = null;
    })
    .addCase("blogCategory/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = true;
      state.createError = null;
      state.newBlogCategory = action.payload;
    })
    .addCase("blogCategory/createError", (state, action) => {
      state.isCreating = false;
      state.createSuccess = false;
      state.createError = action.payload;
      state.newBlogCategory = null;
    })
    .addCase("blogCategory/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.blogCategories = null;
    })
    .addCase("blogCategory/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = true;
      state.fetchError = null;
      state.blogCategories = action.payload;
    })
    .addCase("blogCategory/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = false;
      state.fetchError = action.payload;
      state.blogCategories = null;
    })
    .addCase("blogCategory/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("blogCategory/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("blogCategory/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default blogCategoryReducer;
