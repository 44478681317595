import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./login";
import blogCategoryReducer from "./blogCategory";
import blogReducer from "./blog";
import productCategoryReducer from "./productCategory";
import productReducer from "./product";
import placeReducer from "./pickupPlace";
import couponReducer from "./coupon";
import orderReducer from "./order";
import statReducer from "./stat";
import GalleryReducer from "./gallery";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    blogCategory: blogCategoryReducer,
    blog: blogReducer,
    productCategory: productCategoryReducer,
    product: productReducer,
    place: placeReducer,
    coupon: couponReducer,
    order: orderReducer,
    stat: statReducer,
    gallery: GalleryReducer,
  },
});
