import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Sidebar from "./components/sidebar/Sidebar";
import ThemeSettings from "./components/themeSettings/ThemeSettings";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { useStateContext } from "./context/ContextProvider";
import { lazy } from "react";

const Product = lazy(() => import("./pages/product/Product"));
const Category = lazy(() => import("./pages/category/Category"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const ProductList = lazy(() => import("./pages/productList/ProductList"));
const CategoryList = lazy(() => import("./pages/categoryList/CategoryList"));
const BlogList = lazy(() => import("./pages/blogList/BlogList"));
const Blog = lazy(() => import("./pages/blog/Blog"));
const LoginPage = lazy(() => import("./pages/login/Login"));
const ForgotPasswordPage = lazy(() =>
  import("./pages/forgotPassword/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("./pages/resetPassword/ResetPasswordPage")
);
const AdminProfile = lazy(() => import("./pages/adminProfile/AdminProfile"));
const BlogCategory = lazy(() => import("./pages/blogCategory/BlogCategory"));
const BlogCategoryList = lazy(() =>
  import("./pages/blogCategoryList/BlogCategoryList")
);
const GiftCard = lazy(() => import("./pages/giftCard/GiftCard"));
const PickUpPlace = lazy(() => import("./pages/pick-up-place/PickUpPlace"));
const SingleOrder = lazy(() => import("./pages/singleOrder/SingleOrder"));
const GiftCardList = lazy(() => import("./pages/gift-card-list/GiftCardList"));
const Gallery = lazy(() => import("./pages/gallery/Gallery"));
const NotFound = lazy(() => import("./pages/404/NotFound"));

const App = () => {
  const { activeMenu, themeSettings, currentMode } = useStateContext();

  localStorage.setItem("lastVisit", Date.now());

  const twoDaysInMilliseconds = 1 * 24 * 60 * 60 * 1000;
  setInterval(() => {
    const lastVisit = localStorage.getItem("lastVisit");

    if (Date.now() - lastVisit > twoDaysInMilliseconds) {
      // Clear the localStorage
      localStorage.clear();
      // Stop checking for inactivity
      clearInterval();
    }
  }, twoDaysInMilliseconds);

  const admin = localStorage.getItem("admin") && localStorage.getItem("admin");

  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      {admin ? (
        <BrowserRouter>
          <div className="flex relative dark:bg-main-dark-bg">
            {activeMenu ? (
              <div
                className="w-72 fixed sidebar
              dark:bg-secondary-dark-bg
              bg-[#32412b]"
              >
                <Sidebar />
              </div>
            ) : (
              <div
                className="w-0
              dark:bg-secondary-dark-bg"
              >
                <Sidebar />
              </div>
            )}
            <div
              className={`dark:bg-main-dark-bg bg-main-bg min-h-screen w-full
              ${activeMenu ? "md:ml-72" : "flex-2 "}`}
            >
              <div
                className="fixed md:static
                  bg-main-bg dark:bg-main-dark-bg navbar w-full"
              >
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/login" element={<Navigate to="/" replace />} />
                  <Route
                    path="/forgot-password"
                    element={<Navigate to="/" replace />}
                  />
                  <Route
                    path="/reset-password/:reset_token"
                    element={<Navigate to="/" replace />}
                  />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/product" element={<Product />} />
                  <Route path="/category" element={<Category />} />
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/order/:id" element={<SingleOrder />} />
                  <Route path="/product-list" element={<ProductList />} />
                  <Route path="/category-list" element={<CategoryList />} />
                  <Route path="/blog-list" element={<BlogList />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog-category" element={<BlogCategory />} />
                  <Route path="/gallery" element={<Gallery />} />
                  <Route
                    path="/blog-category-list"
                    element={<BlogCategoryList />}
                  />
                  <Route path="/profile" element={<AdminProfile />} />
                  <Route path="/gift-card" element={<GiftCard />} />
                  <Route path="/gift-card-list" element={<GiftCardList />} />
                  <Route
                    path="/order-pickup-places"
                    element={<PickUpPlace />}
                  />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route
              path="/reset-password/:reset_token"
              element={<ResetPasswordPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
