import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest } from "../middleware/utils";

const initialState = {
  fetchingTotalOrders: false,
  totalOrders: null,
  fetchingTotalSells: false,
  totalSells: null,
};

export const totalSells = () => async (dispatch) => {
  dispatch({ type: "stat/fetchingTotalSells" });
  try {
    const response = await authenticatedRequest.get(`/stats/total-sells`);

    dispatch({
      type: "stat/totalSells",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "stat/errorTotalSells",
      payload: error.response.data.message,
    });
  }
};

export const totalOrders = () => async (dispatch) => {};

const statReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("stat/fetchingTotalOrders", (state) => {
      state.fetchingTotalOrders = true;
      state.totalOrders = null;
    })
    .addCase("stat/totalOrders", (state, action) => {
      state.fetchingTotalOrders = false;
      state.totalOrders = action.payload;
    })
    .addCase("stat/errorTotalOrders", (state, action) => {
      state.fetchingTotalOrders = false;
      state.totalOrders = action.payload;
    })
    .addCase("stat/fetchingTotalSells", (state) => {
      state.fetchingTotalSells = true;
      state.totalSells = null;
    })
    .addCase("stat/totalSells", (state, action) => {
      state.fetchingTotalSells = false;
      state.totalSells = action.payload;
    })
    .addCase("stat/errorTotalSells", (state, action) => {
      state.fetchingTotalSells = false;
      state.totalSells = action.payload;
    });
});

export default statReducer;
