import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest } from "../middleware/utils";

const initialState = {
  isCreating: false,
  createSuccess: null,
  createError: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isUpdating: false,
  updateSuccess: null,
  updateError: null,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const updateOrderStatus = (data) => async (dispatch) => {
  dispatch({ type: "order/isUpdating" });

  try {
    const response = await authenticatedRequest.put(
      `/orders/update-order-status/${data.id}`,
      {
        status: data.status,
      },
      { withCredentials: true }
    );

    dispatch({
      type: "order/updateSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "order/updateError",
      payload: error.response.data.message,
    });
  }
};

export const updatePaymentStatus = (data) => async (dispatch) => {
  dispatch({ type: "order/isUpdating" });

  try {
    const response = await authenticatedRequest.put(
      `/orders/update-payment-status/${data.id}`,
      {
        status: data.status,
      },
      { withCredentials: true }
    );

    dispatch({
      type: "order/updateSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "order/updateError",
      payload: error.response.data.message,
    });
  }
};

export const fetchOrders = () => async (dispatch) => {
  dispatch({ type: "order/isFetching" });

  try {
    const response = await authenticatedRequest.get(`/orders/get-all-orders`);

    dispatch({
      type: "order/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "order/fetchError",
      payload: error.response.data.message,
    });
  }
};

export const deleteorder = (id) => async (dispatch) => {
  dispatch({ type: "order/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/orders/delete-order/${id}`
    );

    dispatch({
      type: "order/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "order/deleteError",
      payload: error.response.data.message,
    });
  }
};

const orderReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("order/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = null;
      state.createError = null;
    })
    .addCase("order/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = action.payload;
      state.createError = null;
    })
    .addCase("order/createError", (state, action) => {
      state.isCreating = false;
      state.createSuccess = null;
      state.createError = action.payload;
    })
    .addCase("order/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.blogCategories = null;
    })
    .addCase("order/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = action.payload;
      state.fetchError = null;
    })
    .addCase("order/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = null;
      state.fetchError = action.payload;
    })
    .addCase("order/isUpdating", (state) => {
      state.isUpdating = true;
      state.updateSuccess = null;
      state.updateError = null;
    })
    .addCase("order/updateSuccess", (state, action) => {
      state.isUpdating = false;
      state.updateSuccess = action.payload;
      state.updateError = null;
    })
    .addCase("order/updateError", (state, action) => {
      state.isUpdating = false;
      state.updateSuccess = null;
      state.updateError = action.payload;
    })
    .addCase("order/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("order/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("order/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default orderReducer;
