import { createReducer } from "@reduxjs/toolkit";
import { authenticatedRequest } from "../middleware/utils";

const initialState = {
  isCreating: false,
  createSuccess: null,
  createError: null,
  isFetching: false,
  fetchingError: null,
  fetchSuccess: false,
  isDeleting: false,
  deleteSuccess: null,
  deleteError: null,
};

export const createCoupon = (data) => async (dispatch) => {
  dispatch({ type: "coupon/isCreating" });

  try {
    const response = await authenticatedRequest.post(
      `/coupons/create-coupon`,
      data,
      { withCredentials: true }
    );

    dispatch({
      type: "coupon/createSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "coupon/createError",
      payload: error.response.data.message,
    });
  }
};

export const fetchCoupons = () => async (dispatch) => {
  dispatch({ type: "coupon/isFetching" });

  try {
    const response = await authenticatedRequest.get(`/coupons/get-coupons`);

    dispatch({
      type: "coupon/fetchSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "coupon/fetchError",
      payload: error.response.data.message,
    });
  }
};

export const deleteCoupon = (id) => async (dispatch) => {
  dispatch({ type: "coupon/isDeleting" });

  try {
    const response = await authenticatedRequest.delete(
      `/coupons/delete-coupon/${id}`
    );

    dispatch({
      type: "coupon/deleteSuccess",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "coupon/deleteError",
      payload: error.response.data.message,
    });
  }
};

const couponReducer = createReducer(initialState, (builder) => {
  builder
    .addCase("coupon/isCreating", (state) => {
      state.isCreating = true;
      state.createSuccess = null;
      state.createError = null;
    })
    .addCase("coupon/createSuccess", (state, action) => {
      state.isCreating = false;
      state.createSuccess = action.payload;
      state.createError = null;
    })
    .addCase("coupon/createError", (state, action) => {
      state.isCreating = false;
      state.createSuccess = null;
      state.createError = action.payload;
    })
    .addCase("coupon/isFetching", (state) => {
      state.isFetching = true;
      state.fetchSuccess = false;
      state.fetchError = null;
      state.blogCategories = null;
    })
    .addCase("coupon/fetchSuccess", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = action.payload;
      state.fetchError = null;
    })
    .addCase("coupon/fetchError", (state, action) => {
      state.isFetching = false;
      state.fetchSuccess = null;
      state.fetchError = action.payload;
    })
    .addCase("coupon/isDeleting", (state) => {
      state.isDeleting = true;
      state.deleteSuccess = null;
      state.deleteError = null;
    })
    .addCase("coupon/deleteSuccess", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = action.payload;
      state.deleteError = null;
    })
    .addCase("coupon/deleteError", (state, action) => {
      state.isDeleting = false;
      state.deleteSuccess = null;
      state.deleteError = action.payload;
    });
});

export default couponReducer;
