import React from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useStateContext } from "../../context/ContextProvider";
import {
  AiOutlineUnorderedList,
  AiOutlineShoppingCart,
  AiFillGift,
} from "react-icons/ai";
import { FiShoppingBag } from "react-icons/fi";
import { IoMdContacts } from "react-icons/io";
import { RiContactsLine } from "react-icons/ri";
import { GrGallery } from "react-icons/gr";
import { FaLayerGroup, FaGifts } from "react-icons/fa";

const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "dashboard",
        icon: <FiShoppingBag />,
      },
    ],
  },
  {
    title: "Orders",
    links: [
      {
        name: "orders",
        icon: <AiOutlineShoppingCart />,
      },
    ],
  },
  {
    title: "Shop",
    links: [
      {
        name: "product",
        icon: <FiShoppingBag />,
      },
      {
        name: "product-list",
        icon: <AiOutlineUnorderedList />,
      },
      {
        name: "category",
        icon: <FiShoppingBag />,
      },
      {
        name: "category-list",
        icon: <FiShoppingBag />,
      },
    ],
  },
  {
    title: "Blogs",
    links: [
      {
        name: "blog",
        icon: <IoMdContacts />,
      },
      {
        name: "blog-List",
        icon: <FaLayerGroup />,
      },
      {
        name: "blog-category",
        icon: <RiContactsLine />,
      },
      {
        name: "blog-category-List",
        icon: <FaLayerGroup />,
      },
    ],
  },
  {
    title: "Settings",
    links: [
      {
        name: "order-pickup-places",
        icon: <FiShoppingBag />,
      },
      {
        name: "gift-card",
        icon: <AiFillGift />,
      },
      {
        name: "gift-card-list",
        icon: <FaGifts />,
      },
      {
        name: "gallery",
        icon: <GrGallery />,
      },
    ],
  },
];

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const handleClosedSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-[#32412b]  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-white dark:text-gray-200 hover:bg-gray-100 hover:text-[#32412b] m-2";

  return (
    <div>
      <div
        className="ml-3 h-screen
           md:overflow-hidden overflow-auto
           md:hover:overflow-auto pb-10"
      >
        {activeMenu && (
          <>
            <div className="flex justify-between items-center">
              <Link
                to="/"
                onClick={handleClosedSideBar}
                className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight text-white"
              >
                <span>SINI COFFEE ADMIN</span>
              </Link>
              <TooltipComponent content="Menu" position="BottomCenter">
                <button
                  type="button"
                  onClick={() => setActiveMenu(!activeMenu)}
                  className=" text-xl text-white rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
                >
                  <MdOutlineCancel />
                </button>
              </TooltipComponent>
            </div>
            <div className="mt-10">
              {links.map((item) => (
                <div key={item.title}>
                  <p className="text-white m-3 mt-4 uppercase">{item.title}</p>
                  {item.links.map((link) => (
                    <NavLink
                      to={`/${link.name}`}
                      key={link.name}
                      onClick={handleClosedSideBar}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "white" : "",
                      })}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      {link.icon}
                      <span className="capitalize font-bold">{link.name}</span>
                    </NavLink>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
