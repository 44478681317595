const Button = ({
  bgColor,
  color,
  icon,
  size,
  text,
  borderRadius,
  customFun,
}) => {
  return (
    <button
      type="button"
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`text-${size} p-3 hover:drop-shadow-xl`}
      onClick={customFun}
    >
      {text}
      {icon}
    </button>
  );
};

export default Button;
