import "./Stat.scss";
import Loader from "../loader/Loader";

const Stat = ({ title, amount, desc, isLoading }) => {
  return (
    <div className="stat">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="statWrapper">
          <div className="left">
            <span className="title">{title}</span>
            <span className="amount">{amount}</span>
          </div>
          <div className="right">
            <span className="compared">{desc}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Stat;
